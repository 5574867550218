/*             */
function fetchWithTimeout(url, options={}, timeout=1000) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const headers = (options && options.headers) ? options.headers : {};
    headers['X-Requested-With'] = 'XMLHttpRequest';
    options['headers'] = headers;
    return fetch(url, {
        ...options,
        signal: controller.signal
    }).then(response => {
        clearTimeout(id);
        switch (response.status) {
            case 200:
                try {
                    return response.json();
                } catch (e) {
                    console.log("Could not parse json from response: " + response);
                    return [];
                }
                break;
            case 204:
                return [];
                break;
            default:
                return [];
                break;
    }});
}

/*             */
function getVisitorId() {
    const cookie = document.cookie;

    if (!cookie) return null;

    const cookieValue = cookie.split("; ").find(row => row.startsWith("visitorId"));
    return cookieValue ? cookieValue.split("=")[1] : null;
}

/*             */
async function fetchLocalRecos(visitorId) {
    return fetchWithTimeout('/nav-customizer/local-recos', {headers: {vid: visitorId}});
}

function clickTrackingData(title, position, type) {
    return JSON.stringify(
        [{
            "method": "trackOnNextPageImpression",
            "dataContainer": {
                "wk.nav_MenuType": "curated",
                "wk.nav_PersonalisedCategories": "true",
                "san_Interaction": "local_navigation",
                "wk.promo_AttributionFeature": "LocalNavigationRecommendation_Storefront",
                "wk.nav_LocalNavigationItemStorefront": title,
                "wk.nav_LocalNavigationTypeStorefront": "recommendation_" + type,
                "promo_Click": "LocalNavigationRecommendation",
                "wk.nav_MenuRank": "2",
                "san_Navigation": "local",
                "nav_MenuAction": "click",
                "wk.nav_MenuLevel": "3",
                "wk.nav_MenuTitle": title
            }
        },
            {
                "method": "submitMoveAction",
                "dataContainer": {},
                "action": {
                    "name": "click",
                    "features": [
                        {
                            "id": "nav_local_navigation_recommendation",
                            "name": "LocalNavigationRecommendation",
                            "labels": {
                                "promo_Feature": ["true"],
                                "nav_PersonalisedCategories": ["true"],
                                "nav_LocalCluster": ["recommendation"]
                            },
                            "status": "clicked"
                        },
                        {
                            "id": "nav_local_navigation_recommendation_chip",
                            "name": "LocalNavigationRecommendation-Chips",
                            "labels": {
                                "nav_LocalCluster": ["recommendation"],
                                "nav_PersonalisedCategories": ["true"],
                                "nav_LocalChipName": [title]
                            },
                            "parentId": "nav_local_navigation_recommendation",
                            "position": position,
                            "status": "clicked"
                        }
                    ]
                }
            }]);
}

function createTile (item, uuid, index) {
    if(item instanceof HTMLElement) return item;

    const div = document.createElement('div');
    const a = document.createElement('a');
    a.className = 'nav_tile ts-link';
    a.href = item.href;
    a.dataset.featureUuid = uuid;
    a.dataset.id = "nav_local_navigation_recommendation_chip";
    a.dataset.name = "LocalNavigationRecommendation-Chip";
    a.dataset.navTrack = "true";
    a.dataset.navTrackingItem = "true";
    a.dataset.navType = "curated";
    a.dataset.navLocalcluster = "recommendation";
    a.dataset.promoFeature = "true";
    a.dataset.status = "loaded";
    a.dataset.suffix = "EntryPage";
    a.dataset.navTitle = item.trackingTitle;
    a.dataset.navPersonalisedcategories =  "true";
    a.dataset.position = index.toString();
    a.dataset.navTrackingClickCategorySuggestionsTileItem = clickTrackingData(item.trackingTitle, index, "tiles");

    const span = document.createElement('span');
    span.className = 'nav_tile__title';
    const span2 = document.createElement('span');
    span2.className = 'nav_tile__text pl_copy75';
    span2.innerHTML = item.titleChunks.join('&shy;');
    span.appendChild(span2);

    const picture = document.createElement('picture');
    picture.className = 'nav_tile__image';

    const s1 = document.createElement('source');
    s1.media = "(max-width: 27.99em)";
    s1.srcset = item.imageUri + "?w=200&h=200&upscale=true&fmt=webp";
    picture.appendChild(s1);

    const s2 = document.createElement('source');
    s2.media = "(min-width: 28em) and (max-width: 47.99em)";
    s2.srcset = item.imageUri + "?w=450&h=450&upscale=true&fmt=webp";
    picture.appendChild(s2);

    const s3 = document.createElement('source');
    s3.media = "(min-width: 48em) and (max-width: 61.99em)";
    s3.srcset = item.imageUri + "?w=200&h=200&upscale=true&fmt=webp";
    picture.appendChild(s3);

    const s4 = document.createElement('source');
    s4.media = "(min-width: 62em)";
    s4.srcset = item.imageUri + "?w=400&h=400&upscale=true&fmt=webp";
    picture.appendChild(s4)

    const img = document.createElement('img');
    img.src = item.imageUri + "?w=200&h=200&upscale=true"
    picture.appendChild(img);

    a.appendChild(span);
    a.appendChild(picture);
    div.appendChild(a);

    return div;
}

function createChip (item, uuid, index) {
    if(item instanceof HTMLElement) return item;

    const a = document.createElement('a');
    a.className = 'pl_media-object-wrapper nav_double-slider__chip';
    a.href = item.href;
    a.dataset.featureUuid = uuid;
    a.dataset.id = "nav_local_navigation_recommendation_chip";
    a.dataset.name = "LocalNavigationRecommendation-Chip";
    a.dataset.navTrack = "true";
    a.dataset.navTrackingItem = "true";
    a.dataset.navType = "curated";
    a.dataset.navLocalcluster = "recommendation";
    a.dataset.promoFeature = "true";
    a.dataset.status = "loaded";
    a.dataset.suffix = "EntryPage";
    a.dataset.navTitle = item.trackingTitle;
    a.dataset.navPersonalisedcategories =  "true";
    a.dataset.position = index.toString();
    a.dataset.navTrackingClickCategorySuggestionsDoubleSliderItem = clickTrackingData(item.trackingTitle, index, "slider");

    const span = document.createElement('span');
    span.className = 'pl_media-object75 nav_double-slider-chip-content';

    const picture = document.createElement('picture');
    picture.className = 'pl_media-object__image pl_media-object__image--border50 nav_double-slider-chip-content__image';

    const s1 = document.createElement('source');
    s1.srcset = item.imageUri + "?w=64&h=64&upscale=true&fmt=webp 1x, " +
        item.imageUri + "?w=128&h=128&upscale=true&fmt=webp 2x, " +
        item.imageUri + "?w=192&h=192&upscale=true&fmt=webp 3x";
    s1.type = "image/webp";
    picture.appendChild(s1);

    const s2 = document.createElement('source');
    s2.srcset = item.imageUri + "?w=64&h=64&upscale=true 1x, " +
        item.imageUri + "?w=128&h=128&upscale=true 2x, " +
        item.imageUri + "?w=192&h=192&upscale=true 3x";
    picture.appendChild(s2);

    const img = document.createElement('img');
    img.src = item.imageUri + "?w=128&h=128&upscale=true"
    picture.appendChild(img);

    const content = document.createElement('span');
    content.className = 'pl_media-object__content nav_double-slider-chip-content__text';
    const headline = document.createElement('span');
    headline.className = 'pl_media-object__headline';
    headline.innerHTML = item.titleChunks.join('&shy;');
    content.appendChild(headline);

    span.appendChild(picture);
    span.appendChild(content);

    a.appendChild(span);
    return a;
}

function createSlide (topContent, bottomContent) {
    const slide = document.createElement('li');
    slide.className = 'pl_carousel__slide nav_double-slider__slide pl_carousel__slide--active';
    const top = document.createElement('div');
    top.className = 'nav_double-slider__slide-top';
    top.appendChild(topContent);
    const bottom = document.createElement('div');
    bottom.appendChild(bottomContent);

    slide.appendChild(top);
    slide.appendChild(bottom);
    return slide;
}

function pairwise (array) {
    const result = [];
    for (let i = 0; i < array.length; i += 2) {
        const chunk = array.slice(i, i + 2);
        result.push(chunk);
    }
    return result;
}

function arrayEmpty(x) {
    return Array.isArray(x) && x.length === 0
}

function verifyCategoryComplete(category) {
    let expectedStringKeys = ["href", "imageUri", "trackingTitle"];
    return (expectedStringKeys.every(key => category.hasOwnProperty(key)) &&
            expectedStringKeys.every(key => category[key] !== null) &&
            !arrayEmpty(category["titleChunks"]));
}

export async function personalizeDoubleSlider(id, sliderContainer) {
    const vid = getVisitorId();
    const customCategoriesBody = await fetchLocalRecos(vid);
    const doubleSlider = sliderContainer.getElementsByClassName("nav_double-slider-scroller")[0];

    /*                                                    */
    const categories = customCategoriesBody.localRecos.filter(verifyCategoryComplete);
    if (categories.length > 0) {
        const header = sliderContainer.querySelector(".nav_category-suggestions-slider__header .pl_headline100");
        header.innerText = "Kategorie Рекомендации для Вас";

        try {
            /*                                                                                         */
            let newHrefs = new Set(categories.map(c => c.href));
            let filteredOldCategories = Array.from(doubleSlider.getElementsByClassName("nav_double-slider__chip"))
                .filter(x => {
                    /*                                                                                   */
                    return !(newHrefs.has(x.getAttribute('href')));
                });
            const chips = Array.from(categories).concat(filteredOldCategories).slice(0, 6);
            const newSlider = pairwise(chips).map((pair, index) => {
                const [top, bottom] = pair;
                return createSlide(createChip(top, id, 2 * index), createChip(bottom, id, 2 * index + 1));
            });
            doubleSlider.innerHTML = '';
            newSlider.forEach(f => doubleSlider.appendChild(f));

            /*                                                   */
            /*                                                                              */
            /*                                                   */
            Array.from(sliderContainer.getElementsByClassName("nav_double-slider__chip"))
                .forEach((cat, index) => cat.dataset.position = index.toString());
        } catch (e) {
            console.log(e);
        }
    }
}

function getHref(el) {
    /*                                                                                                    */
    /*                                                                                   */
    return el.getAttribute('href') || el.dataset.ub64e ? window.atob(el.dataset.ub64e) : "";
}

export async function personalizeTileDesign(id, sliderContainer) {
    const vid = getVisitorId();
    const customCategoriesBody = await fetchLocalRecos(vid);

    /*                                                   */
    const categories = customCategoriesBody.localRecos.filter(verifyCategoryComplete)

    if (categories.length > 0) {
        const header = sliderContainer.querySelector(".nav_category-suggestions-slider__header .pl_headline100");
        header.innerText = "Kategorie Рекомендации для Вас";

        try {
            const tilesContainer = sliderContainer.getElementsByClassName("nav_tiles")[0];
            let removed = 0;

            /*                                                                                         */
            let newHrefs = new Set(categories.map(c => c.href));
            let indexOfDoubles = Array.from(tilesContainer.getElementsByClassName("nav_tile"))
                .map((x, i) => {
                    if (newHrefs.has(getHref(x))) return i;
                })
                .filter(x => x != null) /*                                                             */
                .toSorted((a, b) => b - a); /*                                                                         */
            indexOfDoubles.forEach(i => {
                tilesContainer.removeChild(tilesContainer.children[i]);
                removed+=1;
            });

            const removeIndex = Array.from(sliderContainer.getElementsByClassName("nav_tile")).length -1;
            categories.forEach((cat, index) => {
                if (removed < categories.length) {
                    tilesContainer.removeChild(tilesContainer.children[removeIndex]);
                    removed+=1;
                }
                const tile = createTile(cat, id, index);
                tilesContainer.insertBefore(tile, tilesContainer.children[index]);
            });
            /*                                                   */
            /*                                                                              */
            /*                                                   */
            Array.from(sliderContainer.getElementsByClassName("nav_tile"))
                .forEach((cat, index) => cat.dataset.position = index.toString());
        } catch (e) {
            console.log(e);
        }
    }
}